import React from "react"
import Layout from "./../components/layouts/Default"
import Seo from "./../components/layouts/Seo"

export default function Page() {
  return (
    <Layout>
      <Seo
        title="Datenschutz"
        description="Datenschutz von Florian Dierda - Finanzdienstleistungen"
        path="/datenschutz/"
      />
      <div className="my-16 xl:my-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max-content lg:max-w-7xl mx-auto">
          <div className="relative">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
              />
            </svg>
            <svg
              className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
              />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <section className="mb-8">
                <div className="text-gray-700 mb-6 lg:max-w-3xl lg:mb-0 mx-auto">
                  <div className="mb-8">
                    <div className="relative z-10 mb-16 md:mb-2">
                      <div className="text-lg mx-auto mb-6">
                        <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                          Rechtliches
                        </p>
                        <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                          Datenschutz­erklärung
                        </h1>
                      </div>
                    </div>
                    <div className="prose prose-lg text-gray-700 mb-6">
                      <div>
                        <div>
                          <h2>1. Datenschutz auf einen Blick</h2>
                          <h3>Allgemeine Hinweise</h3>{" "}
                          <p>
                            Die folgenden Hinweise geben einen einfachen
                            Überblick darüber, was mit Ihren personenbezogenen
                            Daten passiert, wenn Sie diese Website besuchen.
                            Personenbezogene Daten sind alle Daten, mit denen
                            Sie persönlich identifiziert werden können.
                            Ausführliche Informationen zum Thema Datenschutz
                            entnehmen Sie unserer unter diesem Text aufgeführten
                            Datenschutzerklärung.
                          </p>
                          <h3>Datenerfassung auf dieser Website</h3>{" "}
                          <h4>
                            Wer ist verantwortlich für die Datenerfassung auf
                            dieser Website?
                          </h4>{" "}
                          <p>
                            Die Datenverarbeitung auf dieser Website erfolgt
                            durch den Websitebetreiber. Dessen Kontaktdaten
                            können Sie dem Impressum dieser Website entnehmen.
                          </p>{" "}
                          <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
                          <p>
                            Ihre Daten werden zum einen dadurch erhoben, dass
                            Sie uns diese mitteilen. Hierbei kann es sich
                            z.&nbsp;B. um Daten handeln, die Sie in ein
                            Kontaktformular eingeben.
                          </p>{" "}
                          <p>
                            Andere Daten werden automatisch oder nach Ihrer
                            Einwilligung beim Besuch der Website durch unsere
                            IT-Systeme erfasst. Das sind vor allem technische
                            Daten (z.&nbsp;B. Internetbrowser, Betriebssystem
                            oder Uhrzeit des Seitenaufrufs). Die Erfassung
                            dieser Daten erfolgt automatisch, sobald Sie diese
                            Website betreten.
                          </p>{" "}
                          <h4>Wofür nutzen wir Ihre Daten?</h4>{" "}
                          <p>
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie
                            Bereitstellung der Website zu gewährleisten. Andere
                            Daten können zur Analyse Ihres Nutzerverhaltens
                            verwendet werden.
                          </p>{" "}
                          <h4>
                            Welche Rechte haben Sie bezüglich Ihrer Daten?
                          </h4>{" "}
                          <p>
                            Sie haben jederzeit das Recht, unentgeltlich
                            Auskunft über Herkunft, Empfänger und Zweck Ihrer
                            gespeicherten personenbezogenen Daten zu erhalten.
                            Sie haben außerdem ein Recht, die Berichtigung oder
                            Löschung dieser Daten zu verlangen. Wenn Sie eine
                            Einwilligung zur Datenverarbeitung erteilt haben,
                            können Sie diese Einwilligung jederzeit für die
                            Zukunft widerrufen. Außerdem haben Sie das Recht,
                            unter bestimmten Umständen die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen. Des Weiteren steht Ihnen ein
                            Beschwerderecht bei der zuständigen Aufsichtsbehörde
                            zu.
                          </p>{" "}
                          <p>
                            Hierzu sowie zu weiteren Fragen zum Thema
                            Datenschutz können Sie sich jederzeit unter der im
                            Impressum angegebenen Adresse an uns wenden.
                          </p>
                          <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>{" "}
                          <p>
                            Beim Besuch dieser Website kann Ihr Surf-Verhalten
                            statistisch ausgewertet werden. Das geschieht vor
                            allem mit sogenannten Analyseprogrammen.
                          </p>{" "}
                          <p>
                            Detaillierte Informationen zu diesen
                            Analyseprogrammen finden Sie in der folgenden
                            Datenschutzerklärung.
                          </p>
                          <h2>
                            2. Hosting und Content Delivery Networks (CDN)
                          </h2>
                          <h3>Externes Hosting</h3>{" "}
                          <p>
                            Diese Website wird bei einem externen Dienstleister
                            gehostet (Hoster). Die personenbezogenen Daten, die
                            auf dieser Website erfasst werden, werden auf den
                            Servern des Hosters gespeichert. Hierbei kann es
                            sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
                            und Kommunikationsdaten, Vertragsdaten,
                            Kontaktdaten, Namen, Websitezugriffe und sonstige
                            Daten, die über eine Website generiert werden,
                            handeln.
                          </p>{" "}
                          <p>
                            Der Einsatz des Hosters erfolgt zum Zwecke der
                            Vertragserfüllung gegenüber unseren potenziellen und
                            bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und
                            im Interesse einer sicheren, schnellen und
                            effizienten Bereitstellung unseres Online-Angebots
                            durch einen professionellen Anbieter (Art. 6 Abs. 1
                            lit. f DSGVO).
                          </p>{" "}
                          <p>
                            Unser Hoster wird Ihre Daten nur insoweit
                            verarbeiten, wie dies zur Erfüllung seiner
                            Leistungspflichten erforderlich ist und unsere
                            Weisungen in Bezug auf diese Daten befolgen.
                          </p>{" "}
                          <p>Wir setzen folgenden Hoster ein:</p>
                          <p>
                            netcup GmbH
                            <br />
                            Daimlerstraße 25
                            <br />
                            D-76185 Karlsruhe
                          </p>
                          <h4>
                            Abschluss eines Vertrages über Auftragsverarbeitung
                          </h4>{" "}
                          <p>
                            Um die datenschutzkonforme Verarbeitung zu
                            gewährleisten, haben wir einen Vertrag über
                            Auftragsverarbeitung mit unserem Hoster geschlossen.
                          </p>
                          <h2>
                            3. Allgemeine Hinweise und Pflicht­informationen
                          </h2>
                          <h3>Datenschutz</h3>{" "}
                          <p>
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                            persönlichen Daten sehr ernst. Wir behandeln Ihre
                            personenbezogenen Daten vertraulich und entsprechend
                            der gesetzlichen Datenschutzvorschriften sowie
                            dieser Datenschutzerklärung.
                          </p>{" "}
                          <p>
                            Wenn Sie diese Website benutzen, werden verschiedene
                            personenbezogene Daten erhoben. Personenbezogene
                            Daten sind Daten, mit denen Sie persönlich
                            identifiziert werden können. Die vorliegende
                            Datenschutzerklärung erläutert, welche Daten wir
                            erheben und wofür wir sie nutzen. Sie erläutert
                            auch, wie und zu welchem Zweck das geschieht.
                          </p>{" "}
                          <p>
                            Wir weisen darauf hin, dass die Datenübertragung im
                            Internet (z.&nbsp;B. bei der Kommunikation per
                            E-Mail) Sicherheitslücken aufweisen kann. Ein
                            lückenloser Schutz der Daten vor dem Zugriff durch
                            Dritte ist nicht möglich.
                          </p>
                          <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
                          <p>
                            Die verantwortliche Stelle für die Datenverarbeitung
                            auf dieser Website ist:
                          </p>{" "}
                          <p>
                            Florian Dierda
                            <br />
                            Florian Dierda - Finanzdienstleistungen
                            <br />
                            Thüringerstr. 9<br />
                            34314 Espenau
                          </p>
                          <p>
                            Telefon: +49 (0) 1516 4406444
                            <br />
                            E-Mail: anfragen@dierda.de
                          </p>
                          <p>
                            Verantwortliche Stelle ist die natürliche oder
                            juristische Person, die allein oder gemeinsam mit
                            anderen über die Zwecke und Mittel der Verarbeitung
                            von personenbezogenen Daten (z.&nbsp;B. Namen,
                            E-Mail-Adressen o. Ä.) entscheidet.
                          </p>
                          <h3>Speicherdauer</h3>{" "}
                          <p>
                            Soweit innerhalb dieser Datenschutzerklärung keine
                            speziellere Speicherdauer genannt wurde, verbleiben
                            Ihre personenbezogenen Daten bei uns, bis der Zweck
                            für die Datenverarbeitung entfällt. Wenn Sie ein
                            berechtigtes Löschersuchen geltend machen oder eine
                            Einwilligung zur Datenverarbeitung widerrufen,
                            werden Ihre Daten gelöscht, sofern wir keinen
                            anderen rechtlich zulässigen&nbsp; Gründe für die
                            Speicherung Ihrer personenbezogenen Daten haben
                            (z.B. steuer- oder handelsrechtliche
                            Aufbewahrungsfristen); im letztgenannten Fall
                            erfolgt die Löschung nach Fortfall dieser Gründe.
                          </p>
                          <h3>Hinweis zur Datenweitergabe in die USA</h3>{" "}
                          <p>
                            Auf unserer Website sind unter anderem Tools von
                            Unternehmen mit Sitz in den USA eingebunden. Wenn
                            diese Tools aktiv sind, können Ihre
                            personenbezogenen Daten an die US-Server der
                            jeweiligen Unternehmen weitergegeben werden. Wir
                            weisen darauf hin, dass die USA kein sicherer
                            Drittstaat im Sinne des EU-Datenschutzrechts sind.
                            US-Unternehmen sind dazu verpflichtet,
                            personenbezogene Daten an Sicherheitsbehörden
                            herauszugeben, ohne dass Sie als Betroffener
                            hiergegen gerichtlich vorgehen könnten. Es kann
                            daher nicht ausgeschlossen werden, dass US-Behörden
                            (z.B. Geheimdienste) Ihre auf US-Servern
                            befindlichen Daten zu Überwachungszwecken
                            verarbeiten, auswerten und dauerhaft speichern. Wir
                            haben auf diese Verarbeitungstätigkeiten keinen
                            Einfluss.
                          </p>
                          <h3>
                            Widerruf Ihrer Einwilligung zur Datenverarbeitung
                          </h3>{" "}
                          <p>
                            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                            ausdrücklichen Einwilligung möglich. Sie können eine
                            bereits erteilte Einwilligung jederzeit widerrufen.
                            Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                            Datenverarbeitung bleibt vom Widerruf unberührt.
                          </p>
                          <h3>
                            Widerspruchsrecht gegen die Datenerhebung in
                            besonderen Fällen sowie gegen Direktwerbung (Art. 21
                            DSGVO)
                          </h3>{" "}
                          <p>
                            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6
                            ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
                            JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER
                            BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
                            IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
                            EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE
                            BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
                            RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
                            WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
                            PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
                            SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE
                            FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE
                            INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
                            DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
                            ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH
                            NACH ART. 21 ABS. 1 DSGVO).
                          </p>{" "}
                          <p>
                            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                            DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
                            JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                            BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
                            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR
                            DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG
                            IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN
                            IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
                            MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
                            (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
                          </p>
                          <h3>
                            Beschwerde­recht bei der zuständigen
                            Aufsichts­behörde
                          </h3>{" "}
                          <p>
                            Im Falle von Verstößen gegen die DSGVO steht den
                            Betroffenen ein Beschwerderecht bei einer
                            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
                            ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                            oder des Orts des mutmaßlichen Verstoßes zu. Das
                            Beschwerderecht besteht unbeschadet anderweitiger
                            verwaltungsrechtlicher oder gerichtlicher
                            Rechtsbehelfe.
                          </p>
                          <h3>Recht auf Daten­übertrag­barkeit</h3>{" "}
                          <p>
                            Sie haben das Recht, Daten, die wir auf Grundlage
                            Ihrer Einwilligung oder in Erfüllung eines Vertrags
                            automatisiert verarbeiten, an sich oder an einen
                            Dritten in einem gängigen, maschinenlesbaren Format
                            aushändigen zu lassen. Sofern Sie die direkte
                            Übertragung der Daten an einen anderen
                            Verantwortlichen verlangen, erfolgt dies nur, soweit
                            es technisch machbar ist.
                          </p>
                          <h3>SSL- bzw. TLS-Verschlüsselung</h3>{" "}
                          <p>
                            Diese Seite nutzt aus Sicherheitsgründen und zum
                            Schutz der Übertragung vertraulicher Inhalte, wie
                            zum Beispiel Bestellungen oder Anfragen, die Sie an
                            uns als Seitenbetreiber senden, eine SSL- bzw.
                            TLS-Verschlüsselung. Eine verschlüsselte Verbindung
                            erkennen Sie daran, dass die Adresszeile des
                            Browsers von „http://“ auf „https://“ wechselt und
                            an dem Schloss-Symbol in Ihrer Browserzeile.
                          </p>{" "}
                          <p>
                            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert
                            ist, können die Daten, die Sie an uns übermitteln,
                            nicht von Dritten mitgelesen werden.
                          </p>
                          <h3>Auskunft, Löschung und Berichtigung</h3>{" "}
                          <p>
                            Sie haben im Rahmen der geltenden gesetzlichen
                            Bestimmungen jederzeit das Recht auf unentgeltliche
                            Auskunft über Ihre gespeicherten personenbezogenen
                            Daten, deren Herkunft und Empfänger und den Zweck
                            der Datenverarbeitung und ggf. ein Recht auf
                            Berichtigung oder Löschung dieser Daten. Hierzu
                            sowie zu weiteren Fragen zum Thema personenbezogene
                            Daten können Sie sich jederzeit unter der im
                            Impressum angegebenen Adresse an uns wenden.
                          </p>
                          <h3>Recht auf Einschränkung der Verarbeitung</h3>{" "}
                          <p>
                            Sie haben das Recht, die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen. Hierzu können Sie sich jederzeit unter
                            der im Impressum angegebenen Adresse an uns wenden.
                            Das Recht auf Einschränkung der Verarbeitung besteht
                            in folgenden Fällen:
                          </p>{" "}
                          <ul>
                            {" "}
                            <li>
                              Wenn Sie die Richtigkeit Ihrer bei uns
                              gespeicherten personenbezogenen Daten bestreiten,
                              benötigen wir in der Regel Zeit, um dies zu
                              überprüfen. Für die Dauer der Prüfung haben Sie
                              das Recht, die Einschränkung der Verarbeitung
                              Ihrer personenbezogenen Daten zu verlangen.
                            </li>{" "}
                            <li>
                              Wenn die Verarbeitung Ihrer personenbezogenen
                              Daten unrechtmäßig geschah/geschieht, können Sie
                              statt der Löschung die Einschränkung der
                              Datenverarbeitung verlangen.
                            </li>{" "}
                            <li>
                              Wenn wir Ihre personenbezogenen Daten nicht mehr
                              benötigen, Sie sie jedoch zur Ausübung,
                              Verteidigung oder Geltendmachung von
                              Rechtsansprüchen benötigen, haben Sie das Recht,
                              statt der Löschung die Einschränkung der
                              Verarbeitung Ihrer personenbezogenen Daten zu
                              verlangen.
                            </li>{" "}
                            <li>
                              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1
                              DSGVO eingelegt haben, muss eine Abwägung zwischen
                              Ihren und unseren Interessen vorgenommen werden.
                              Solange noch nicht feststeht, wessen Interessen
                              überwiegen, haben Sie das Recht, die Einschränkung
                              der Verarbeitung Ihrer personenbezogenen Daten zu
                              verlangen.
                            </li>{" "}
                          </ul>{" "}
                          <p>
                            Wenn Sie die Verarbeitung Ihrer personenbezogenen
                            Daten eingeschränkt haben, dürfen diese Daten – von
                            ihrer Speicherung abgesehen – nur mit Ihrer
                            Einwilligung oder zur Geltendmachung, Ausübung oder
                            Verteidigung von Rechtsansprüchen oder zum Schutz
                            der Rechte einer anderen natürlichen oder
                            juristischen Person oder aus Gründen eines wichtigen
                            öffentlichen Interesses der Europäischen Union oder
                            eines Mitgliedstaats verarbeitet werden.
                          </p>
                          <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
                          <p>
                            Der Nutzung von im Rahmen der Impressumspflicht
                            veröffentlichten Kontaktdaten zur Übersendung von
                            nicht ausdrücklich angeforderter Werbung und
                            Informationsmaterialien wird hiermit widersprochen.
                            Die Betreiber der Seiten behalten sich ausdrücklich
                            rechtliche Schritte im Falle der unverlangten
                            Zusendung von Werbeinformationen, etwa durch
                            Spam-E-Mails, vor.
                          </p>
                          <h2>4. Datenerfassung auf dieser Website</h2>
                          <h3>Cookies</h3>{" "}
                          <p>
                            Unsere Internetseiten verwenden so genannte
                            „Cookies“. Cookies sind kleine Textdateien und
                            richten auf Ihrem Endgerät keinen Schaden an. Sie
                            werden entweder vorübergehend für die Dauer einer
                            Sitzung (Session-Cookies) oder dauerhaft (permanente
                            Cookies) auf Ihrem Endgerät gespeichert.
                            Session-Cookies werden nach Ende Ihres Besuchs
                            automatisch gelöscht. Permanente Cookies bleiben auf
                            Ihrem Endgerät gespeichert, bis Sie diese selbst
                            löschen&nbsp;oder eine automatische Löschung durch
                            Ihren Webbrowser erfolgt.
                          </p>{" "}
                          <p>
                            Teilweise können auch Cookies von Drittunternehmen
                            auf Ihrem Endgerät gespeichert werden, wenn Sie
                            unsere Seite betreten (Third-Party-Cookies). Diese
                            ermöglichen uns oder Ihnen die Nutzung bestimmter
                            Dienstleistungen des Drittunternehmens (z.B. Cookies
                            zur Abwicklung von Zahlungsdienstleistungen).
                          </p>{" "}
                          <p>
                            Cookies haben verschiedene Funktionen. Zahlreiche
                            Cookies sind technisch notwendig, da bestimmte
                            Websitefunktionen ohne diese nicht funktionieren
                            würden (z.B. die Warenkorbfunktion oder die Anzeige
                            von Videos). Andere Cookies dienen dazu, das
                            Nutzerverhalten auszuwerten&nbsp;oder Werbung
                            anzuzeigen.
                          </p>{" "}
                          <p>
                            Cookies, die zur Durchführung des elektronischen
                            Kommunikationsvorgangs (notwendige Cookies) oder zur
                            Bereitstellung bestimmter, von Ihnen erwünschter
                            Funktionen (funktionale Cookies, z. B. für die
                            Warenkorbfunktion) oder zur Optimierung der Website
                            (z.B. Cookies zur Messung des Webpublikums)
                            erforderlich sind, werden auf Grundlage von Art. 6
                            Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
                            Rechtsgrundlage angegeben wird. Der Websitebetreiber
                            hat ein berechtigtes Interesse an der Speicherung
                            von Cookies zur technisch fehlerfreien und
                            optimierten Bereitstellung seiner Dienste. Sofern
                            eine Einwilligung zur Speicherung von Cookies
                            abgefragt wurde, erfolgt die Speicherung der
                            betreffenden Cookies ausschließlich auf Grundlage
                            dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO);
                            die Einwilligung ist jederzeit widerrufbar.
                          </p>{" "}
                          <p>
                            Sie können Ihren Browser so einstellen, dass Sie
                            über das Setzen von Cookies informiert werden und
                            Cookies nur im Einzelfall erlauben, die Annahme von
                            Cookies für bestimmte Fälle oder generell
                            ausschließen sowie das automatische Löschen der
                            Cookies beim Schließen des Browsers aktivieren. Bei
                            der Deaktivierung von Cookies kann die
                            Funktionalität dieser Website eingeschränkt sein.
                          </p>{" "}
                          <p>
                            Soweit Cookies von Drittunternehmen oder zu
                            Analysezwecken eingesetzt werden, werden wir Sie
                            hierüber im Rahmen dieser Datenschutzerklärung
                            gesondert informieren und ggf. eine Einwilligung
                            abfragen.
                          </p>
                          <h3>Server-Log-Dateien</h3>{" "}
                          <p>
                            Der Provider der Seiten erhebt und speichert
                            automatisch Informationen in so genannten
                            Server-Log-Dateien, die Ihr Browser automatisch an
                            uns übermittelt. Dies sind:
                          </p>{" "}
                          <ul>
                            {" "}
                            <li>Browsertyp und Browserversion</li>{" "}
                            <li>verwendetes Betriebssystem</li>{" "}
                            <li>Referrer URL</li>{" "}
                            <li>Hostname des zugreifenden Rechners</li>{" "}
                            <li>Uhrzeit der Serveranfrage</li>{" "}
                            <li>IP-Adresse</li>{" "}
                          </ul>{" "}
                          <p>
                            Eine Zusammenführung dieser Daten mit anderen
                            Datenquellen wird nicht vorgenommen.
                          </p>{" "}
                          <p>
                            Die Erfassung dieser Daten erfolgt auf Grundlage von
                            Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
                            ein berechtigtes Interesse an der technisch
                            fehlerfreien Darstellung und der Optimierung seiner
                            Website – hierzu müssen die Server-Log-Files erfasst
                            werden.
                          </p>
                          <h3>Kontaktformular</h3>{" "}
                          <p>
                            Wenn Sie uns per Kontaktformular Anfragen zukommen
                            lassen, werden Ihre Angaben aus dem Anfrageformular
                            inklusive der von Ihnen dort angegebenen
                            Kontaktdaten zwecks Bearbeitung der Anfrage und für
                            den Fall von Anschlussfragen bei uns gespeichert.
                            Diese Daten geben wir nicht ohne Ihre Einwilligung
                            weiter.
                          </p>{" "}
                          <p>
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage
                            von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage
                            mit der Erfüllung eines Vertrags zusammenhängt oder
                            zur Durchführung vorvertraglicher Maßnahmen
                            erforderlich ist. In allen übrigen Fällen beruht die
                            Verarbeitung auf unserem berechtigten Interesse an
                            der effektiven Bearbeitung der an uns gerichteten
                            Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern
                            diese abgefragt wurde.
                          </p>{" "}
                          <p>
                            Die von Ihnen im Kontaktformular eingegebenen Daten
                            verbleiben bei uns, bis Sie uns zur Löschung
                            auffordern, Ihre Einwilligung zur Speicherung
                            widerrufen oder der Zweck für die Datenspeicherung
                            entfällt (z.&nbsp;B. nach abgeschlossener
                            Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
                            Bestimmungen – insbesondere Aufbewahrungsfristen –
                            bleiben unberührt.
                          </p>
                          <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
                          <p>
                            Wenn Sie uns per E-Mail, Telefon oder Telefax
                            kontaktieren, wird Ihre Anfrage inklusive aller
                            daraus hervorgehenden personenbezogenen Daten (Name,
                            Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
                            bei uns gespeichert und verarbeitet. Diese Daten
                            geben wir nicht ohne Ihre Einwilligung weiter.
                          </p>{" "}
                          <p>
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage
                            von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage
                            mit der Erfüllung eines Vertrags zusammenhängt oder
                            zur Durchführung vorvertraglicher Maßnahmen
                            erforderlich ist. In allen übrigen Fällen beruht die
                            Verarbeitung auf unserem berechtigten Interesse an
                            der effektiven Bearbeitung der an uns gerichteten
                            Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern
                            diese abgefragt wurde.
                          </p>{" "}
                          <p>
                            Die von Ihnen an uns per Kontaktanfragen übersandten
                            Daten verbleiben bei uns, bis Sie uns zur Löschung
                            auffordern, Ihre Einwilligung zur Speicherung
                            widerrufen oder der Zweck für die Datenspeicherung
                            entfällt (z.&nbsp;B. nach abgeschlossener
                            Bearbeitung Ihres Anliegens). Zwingende gesetzliche
                            Bestimmungen – insbesondere gesetzliche
                            Aufbewahrungsfristen – bleiben unberührt.
                          </p>
                          <h2>5. Analyse-Tools und Werbung</h2>
                          <h3>Google Analytics</h3>{" "}
                          <p>
                            Diese Website nutzt Funktionen des
                            Webanalysedienstes Google Analytics. Anbieter ist
                            die Google Ireland Limited („Google“), Gordon House,
                            Barrow Street, Dublin 4, Irland.
                          </p>{" "}
                          <p>
                            Google Analytics ermöglicht es dem Websitebetreiber,
                            das Verhalten der Websitebesucher zu analysieren.
                            Hierbei erhält der Websitebetreiber verschiedene
                            Nutzungsdaten, wie z.B. Seitenaufrufe, Verweildauer,
                            verwendete Betriebssysteme und Herkunft des Nutzers.
                            Diese Daten werden von Google ggf. in einem Profil
                            zusammengefasst, das dem jeweiligen Nutzer bzw.
                            dessen Endgerät zugeordnet ist.
                          </p>{" "}
                          <p>
                            Google Analytics verwendet Technologien, die die
                            Wiedererkennung des Nutzers zum Zwecke der Analyse
                            des Nutzerverhaltens ermöglichen (z.B. Cookies oder
                            Device-Fingerprinting). Die von Google erfassten
                            Informationen über die Benutzung dieser Website
                            werden in der Regel an einen Server von Google in
                            den USA übertragen und dort gespeichert.
                          </p>{" "}
                          <p>
                            Die Nutzung dieses Analyse-Tools erfolgt auf
                            Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                            Websitebetreiber hat ein berechtigtes Interesse an
                            der Analyse des Nutzerverhaltens, um sowohl sein
                            Webangebot als auch seine Werbung zu optimieren.
                            Sofern eine entsprechende Einwilligung abgefragt
                            wurde (z. B. eine Einwilligung zur Speicherung von
                            Cookies), erfolgt die Verarbeitung ausschließlich
                            auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                            Einwilligung ist jederzeit widerrufbar.
                          </p>{" "}
                          <h4>IP Anonymisierung</h4>{" "}
                          <p>
                            Wir haben auf dieser Website die Funktion
                            IP-Anonymisierung aktiviert. Dadurch wird Ihre
                            IP-Adresse von Google innerhalb von Mitgliedstaaten
                            der Europäischen Union oder in anderen
                            Vertragsstaaten des Abkommens über den Europäischen
                            Wirtschaftsraum vor der Übermittlung in die USA
                            gekürzt. Nur in Ausnahmefällen wird die volle
                            IP-Adresse an einen Server von Google in den USA
                            übertragen und dort gekürzt. Im Auftrag des
                            Betreibers dieser Website wird Google diese
                            Informationen benutzen, um Ihre Nutzung der Website
                            auszuwerten, um Reports über die Websiteaktivitäten
                            zusammenzustellen und um weitere mit der
                            Websitenutzung und der Internetnutzung verbundene
                            Dienstleistungen gegenüber dem Websitebetreiber zu
                            erbringen. Die im Rahmen von Google Analytics von
                            Ihrem Browser übermittelte IP-Adresse wird nicht mit
                            anderen Daten von Google zusammengeführt.
                          </p>
                          <h4>Browser Plugin</h4>{" "}
                          <p>
                            Sie können die Erfassung und Verarbeitung Ihrer
                            Daten durch Google verhindern, indem Sie das unter
                            dem folgenden Link verfügbare Browser-Plugin
                            herunterladen und installieren:{" "}
                            <a
                              href="https://tools.google.com/dlpage/gaoptout?hl=de"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://tools.google.com/dlpage/gaoptout?hl=de
                            </a>
                            .
                          </p>{" "}
                          <p>
                            Mehr Informationen zum Umgang mit Nutzerdaten bei
                            Google Analytics finden Sie in der
                            Datenschutzerklärung von Google:{" "}
                            <a
                              href="https://support.google.com/analytics/answer/6004245?hl=de"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://support.google.com/analytics/answer/6004245?hl=de
                            </a>
                            .
                          </p>
                          <h4>Auftragsverarbeitung</h4>{" "}
                          <p>
                            Wir haben mit Google einen Vertrag zur
                            Auftragsverarbeitung abgeschlossen und setzen die
                            strengen Vorgaben der deutschen Datenschutzbehörden
                            bei der Nutzung von Google Analytics vollständig um.
                          </p>
                          <h4>Demografische Merkmale bei Google Analytics</h4>{" "}
                          <p>
                            Diese Website nutzt die Funktion „demografische
                            Merkmale“ von Google Analytics, um den
                            Websitebesuchern passende Werbeanzeigen innerhalb
                            des Google-Werbenetzwerks anzeigen zu können.
                            Dadurch können Berichte erstellt werden, die
                            Aussagen zu Alter, Geschlecht und Interessen der
                            Seitenbesucher enthalten. Diese Daten stammen aus
                            interessenbezogener Werbung von Google sowie aus
                            Besucherdaten von Drittanbietern. Diese Daten können
                            keiner bestimmten Person zugeordnet werden. Sie
                            können diese Funktion jederzeit über die
                            Anzeigeneinstellungen in Ihrem Google-Konto
                            deaktivieren oder die Erfassung Ihrer Daten durch
                            Google Analytics wie im Punkt „Widerspruch gegen
                            Datenerfassung“ dargestellt generell untersagen.
                          </p>
                          <h4>Google Analytics E-Commerce-Tracking</h4>{" "}
                          <p>
                            Diese Website nutzt die Funktion
                            „E-Commerce-Tracking“ von Google Analytics. Mit
                            Hilfe von E-Commerce-Tracking kann der
                            Websitebetreiber das Kaufverhalten der
                            Websitebesucher zur Verbesserung seiner
                            Online-Marketing-Kampagnen analysieren. Hierbei
                            werden Informationen, wie zum Beispiel die
                            getätigten Bestellungen, durchschnittliche
                            Bestellwerte, Versandkosten und die Zeit von der
                            Ansicht bis zum Kauf eines Produktes erfasst. Diese
                            Daten können von Google unter einer Transaktions-ID
                            zusammengefasst werden, die dem jeweiligen Nutzer
                            bzw. dessen Gerät zugeordnet ist.
                          </p>
                          <h4>Speicherdauer</h4>{" "}
                          <p>
                            Bei Google gespeicherte Daten auf Nutzer- und
                            Ereignisebene, die mit Cookies, Nutzerkennungen
                            (z.&nbsp;B. User ID) oder Werbe-IDs (z.&nbsp;B.
                            DoubleClick-Cookies, Android-Werbe-ID) verknüpft
                            sind, werden nach 14 Monaten anonymisiert bzw.
                            gelöscht. Details hierzu ersehen Sie unter folgendem
                            Link:{" "}
                            <a
                              href="https://support.google.com/analytics/answer/7667196?hl=de"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://support.google.com/analytics/answer/7667196?hl=de
                            </a>
                          </p>
                          <h3>Hotjar</h3>{" "}
                          <p>
                            Diese Website nutzt Hotjar. Anbieter ist die Hotjar
                            Ltd., Level 2, St Julians Business Centre, 3, Elia
                            Zammit Street, St Julians STJ 1000, Malta, Europe
                            (Website:{" "}
                            <a href target="_blank" rel="noopener noreferrer">
                              https://www.hotjar.com
                            </a>
                            ).
                          </p>{" "}
                          <p>
                            Hotjar ist ein Werkzeug zur Analyse Ihres
                            Nutzerverhaltens auf dieser Website. Mit Hotjar
                            können wir u. a. Ihre Maus- und Scrollbewegungen und
                            Klicks aufzeichnen. Hotjar kann dabei auch
                            feststellen, wie lange Sie mit dem Mauszeiger auf
                            einer bestimmten Stelle verblieben sind. Aus diesen
                            Informationen erstellt Hotjar sogenannte Heatmaps,
                            mit denen sich feststellen lässt, welche
                            Websitebereiche vom Websitebesucher bevorzugt
                            angeschaut werden.
                          </p>{" "}
                          <p>
                            Des Weiteren können wir feststellen, wie lange Sie
                            auf einer Seite verblieben sind und wann Sie sie
                            verlassen haben. Wir können auch feststellen, an
                            welcher Stelle Sie Ihre Eingaben in ein
                            Kontaktformular abgebrochen haben (sog.
                            Conversion-Funnels).
                          </p>{" "}
                          <p>
                            Darüber hinaus können mit Hotjar direkte Feedbacks
                            von Websitebesuchern eingeholt werden. Diese
                            Funktion dient der Verbesserung der Webangebote des
                            Websitebetreibers.
                          </p>{" "}
                          <p>
                            Hotjar verwendet Technologien, die die
                            Wiedererkennung des Nutzers zum Zwecke der Analyse
                            des Nutzerverhaltens ermöglichen (z.B. Cookies oder
                            Einsatz von Device-Fingerprinting).
                          </p>{" "}
                          <p>
                            Die Nutzung dieses Analysetools erfolgt auf
                            Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                            Websitebetreiber hat ein berechtigtes Interesse an
                            der Analyse des Nutzerverhaltens, um sowohl sein
                            Webangebot als auch seine Werbung zu optimieren.
                            Sofern eine entsprechende Einwilligung abgefragt
                            wurde (z. B. eine Einwilligung zur Speicherung von
                            Cookies), erfolgt die Verarbeitung ausschließlich
                            auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                            Einwilligung ist jederzeit widerrufbar.
                          </p>{" "}
                          <h4>Deaktivieren von Hotjar</h4>{" "}
                          <p>
                            Wenn Sie die Datenerfassung durch Hotjar
                            deaktivieren möchten, klicken Sie auf folgenden Link
                            und folgen Sie den dortigen Anweisungen:{" "}
                            <a
                              href="https://www.hotjar.com/opt-out"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.hotjar.com/opt-out
                            </a>
                          </p>{" "}
                          <p>
                            Bitte beachten Sie, dass die Deaktivierung von
                            Hotjar für jeden Browser bzw. für jedes Endgerät
                            separat erfolgen muss.
                          </p>{" "}
                          <p>
                            Nähere Informationen über Hotjar und zu den
                            erfassten Daten entnehmen Sie der
                            Datenschutzerklärung von Hotjar unter dem folgenden
                            Link:{" "}
                            <a
                              href="https://www.hotjar.com/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.hotjar.com/privacy
                            </a>
                          </p>
                          <h4>Vertrag über Auftragsverarbeitung</h4>{" "}
                          <p>
                            Wir haben einen Vertrag über Auftragsverarbeitung
                            mit Hotjar geschlossen, um die strengen europäischen
                            Datenschutzvorschriften umzusetzen.
                          </p>
                          <h3>Google Ads</h3>{" "}
                          <p>
                            Der Websitebetreiber verwendet Google Ads. Google
                            Ads ist ein Online-Werbeprogramm der Google Ireland
                            Limited („Google“), Gordon House, Barrow Street,
                            Dublin 4, Irland.
                          </p>{" "}
                          <p>
                            Google Ads ermöglicht es uns Werbeanzeigen in der
                            Google-Suchmaschine oder auf Drittwebseiten
                            auszuspielen, wenn der Nutzer bestimmte Suchbegriffe
                            bei Google eingibt (Keyword-Targeting). Ferner
                            können zielgerichtete Werbeanzeigen anhand der bei
                            Google vorhandenen Nutzerdaten (z.B. Standortdaten
                            und Interessen) ausgespielt werden
                            (Zielgruppen-Targeting). Wir als Websitebetreiber
                            können diese Daten quantitativ auswerten, indem wir
                            beispielsweise analysieren, welche Suchbegriffe zur
                            Ausspielung unserer Werbeanzeigen geführt haben und
                            wie viele Anzeigen zu entsprechenden Klicks geführt
                            haben.
                          </p>{" "}
                          <p>
                            Die Nutzung von Google Ads erfolgt auf Grundlage von
                            Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
                            ein berechtigtes Interesse an einer möglichst
                            effektiven Vermarktung seiner Dienstleistung
                            Produkte.
                          </p>
                          <h3>Google Remarketing</h3>{" "}
                          <p>
                            Diese Website nutzt die Funktionen von Google
                            Analytics Remarketing. Anbieter ist die Google
                            Ireland Limited („Google“), Gordon House, Barrow
                            Street, Dublin 4, Irland.
                          </p>{" "}
                          <p>
                            Google Remarketing analysiert Ihr Nutzerverhalten
                            auf unserer Website (z.B. Klick auf bestimmte
                            Produkte), um Sie in bestimmte Werbe-Zielgruppen
                            einzuordnen und Ihnen anschließend beim Besuch von
                            anderen Onlineangeboten passende Webebotschaften
                            auszuspielen (Remarketing bzw. Retargeting).
                          </p>{" "}
                          <p>
                            Des Weiteren können die mit Google Remarketing
                            erstellten Werbe-Zielgruppen mit den
                            geräteübergreifenden Funktionen von Google verknüpft
                            werden. Auf diese Weise können interessenbezogene,
                            personalisierte Werbebotschaften, die in
                            Abhängigkeit Ihres früheren Nutzungs- und
                            Surfverhaltens auf einem Endgerät (z. B. Handy) an
                            Sie angepasst wurden auch auf einem anderen Ihrer
                            Endgeräte (z. B. Tablet oder PC) angezeigt werden.
                          </p>{" "}
                          <p>
                            Wenn Sie über einen Google-Account verfügen, können
                            Sie der personalisierten Werbung unter folgendem
                            Link widersprechen:{" "}
                            <a
                              href="https://www.google.com/settings/ads/onweb/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://www.google.com/settings/ads/onweb/
                            </a>
                            .
                          </p>{" "}
                          <p>
                            Die Nutzung von Google Remarketing erfolgt auf
                            Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                            Websitebetreiber hat ein berechtigtes Interesse an
                            einer möglichst effektiven Vermarktung seiner
                            Produkte. Sofern eine entsprechende Einwilligung
                            abgefragt wurde, erfolgt die Verarbeitung
                            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
                            a DSGVO; die Einwilligung ist jederzeit widerrufbar.
                          </p>{" "}
                          <p>
                            Weitergehende Informationen und die
                            Datenschutzbestimmungen finden Sie in der
                            Datenschutzerklärung von Google unter:{" "}
                            <a
                              href="https://policies.google.com/technologies/ads?hl=de"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://policies.google.com/technologies/ads?hl=de
                            </a>
                            .
                          </p>
                          <h4>Zielgruppenbildung mit Kundenabgleich</h4>{" "}
                          <p>
                            Zur Zielgruppenbildung verwenden wir unter anderem
                            den Kundenabgleich von Google Remarketing. Hierbei
                            übergeben wir bestimmte Kundendaten (z.B.
                            E-Mail-Adressen) aus unseren Kundenlisten an Google.
                            Sind die betreffenden Kunden Google-Nutzer und in
                            ihrem Google-Konto eingeloggt, werden ihnen passende
                            Werbebotschaften innerhalb des Google-Netzwerks
                            (z.B. bei YouTube, Gmail oder in der Suchmaschine)
                            angezeigt.
                          </p>
                          <h3>Google Conversion-Tracking</h3>{" "}
                          <p>
                            Diese Website nutzt Google Conversion Tracking.
                            Anbieter ist die Google Ireland Limited („Google“),
                            Gordon House, Barrow Street, Dublin 4, Irland.
                          </p>{" "}
                          <p>
                            Mit Hilfe von Google-Conversion-Tracking können
                            Google und wir erkennen, ob der Nutzer bestimmte
                            Aktionen durchgeführt hat. So können wir
                            beispielsweise auswerten, welche Buttons auf unserer
                            Website wie häufig geklickt und welche Produkte
                            besonders häufig angesehen oder gekauft wurden.
                            Diese Informationen dienen dazu,
                            Conversion-Statistiken zu erstellen. Wir erfahren
                            die Gesamtanzahl der Nutzer, die auf unsere Anzeigen
                            geklickt haben und welche Aktionen sie durchgeführt
                            haben. Wir erhalten keine Informationen, mit denen
                            wir den Nutzer persönlich identifizieren können.
                            Google selbst nutzt zur Identifikation Cookies oder
                            vergleichbare Wiedererkennungstechnologien.
                          </p>{" "}
                          <p>
                            Die Nutzung von Google Conversion-Tracking erfolgt
                            auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                            Websitebetreiber hat ein berechtigtes Interesse an
                            der Analyse des Nutzerverhaltens, um sowohl sein
                            Webangebot als auch seine Werbung zu optimieren.
                            Sofern eine entsprechende Einwilligung abgefragt
                            wurde (z. B. eine Einwilligung zur Speicherung von
                            Cookies), erfolgt die Verarbeitung ausschließlich
                            auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                            Einwilligung ist jederzeit widerrufbar.
                          </p>{" "}
                          <p>
                            Mehr Informationen zu Google Conversion-Tracking
                            finden Sie in den Datenschutzbestimmungen von
                            Google:{" "}
                            <a
                              href="https://policies.google.com/privacy?hl=de"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://policies.google.com/privacy?hl=de
                            </a>
                            .
                          </p>
                          <h3>Google DoubleClick</h3>{" "}
                          <p>
                            Diese Website nutzt Funktionen von Google
                            DoubleClick. Anbieter ist die Google Ireland Limited
                            („Google“), Gordon House, Barrow Street, Dublin 4,
                            Irland, (nachfolgend „DoubleClick“).
                          </p>{" "}
                          <p>
                            DoubleClick wird eingesetzt, um Ihnen
                            interessenbezogene Werbeanzeigen im gesamten
                            Google-Werbenetzwerk anzuzeigen. Die Werbeanzeigen
                            können mit Hilfe von DoubleClick zielgerichtet an
                            die Interessen des jeweiligen Betrachters angepasst
                            werden.&nbsp; So kann unsere Werbung beispielsweise
                            in Google-Suchergebnissen oder in Werbebannern, die
                            mit DoubleClick verbunden sind, angezeigt werden.
                          </p>{" "}
                          <p>
                            Um den Nutzern interessengerechte Werbung anzeigen
                            zu können, muss DoubleClick den jeweiligen
                            Betrachter wiedererkennen und ihm seine besuchten
                            Webseiten, Klicks und sonstigen Informationen zum
                            Nutzerverhalten zuordnen können. Hierzu setzt
                            DoubleClick Cookies oder vergleichbare
                            Wiedererkennungstechnologien (z.B.
                            Device-Fingerprinting) ein. Die erfassten
                            Informationen werden zu einem pseudonymen
                            Nutzerprofil zusammengefasst, um dem betreffenden
                            Nutzer interessengerechte Werbung anzuzeigen.
                          </p>{" "}
                          <p>
                            Die Nutzung von Google DoubleClick erfolgt im
                            Interesse zielgerichteter Werbemaßnahmen. Dies
                            stellt ein berechtigtes Interesse im Sinne des Art.
                            6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
                            Einwilligung abgefragt wurde (z. B. eine
                            Einwilligung zur Speicherung von Cookies), erfolgt
                            die Verarbeitung ausschließlich auf Grundlage von
                            Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
                            jederzeit widerrufbar.
                          </p>{" "}
                          <p>
                            Weitere Informationen zu Widerspruchsmöglichkeiten
                            gegen die von Google eingeblendeten Werbeanzeigen
                            entnehmen Sie folgenden Links:{" "}
                            <a
                              href="https://policies.google.com/technologies/ads"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://policies.google.com/technologies/ads
                            </a>{" "}
                            und{" "}
                            <a
                              href="https://adssettings.google.com/authenticated"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://adssettings.google.com/authenticated
                            </a>
                            .
                          </p>
                          <h3>Facebook Pixel</h3>{" "}
                          <p>
                            Diese Website nutzt zur Konversionsmessung der
                            Besucheraktions-Pixel von Facebook. Anbieter dieses
                            Dienstes ist die Facebook Ireland Limited, 4 Grand
                            Canal Square, Dublin 2, Irland. Die erfassten Daten
                            werden nach Aussage von Facebook jedoch auch in die
                            USA und in andere Drittländer übertragen.
                          </p>{" "}
                          <p>
                            So kann das Verhalten der Seitenbesucher
                            nachverfolgt werden, nachdem diese durch Klick auf
                            eine Facebook-Werbeanzeige auf die Website des
                            Anbieters weitergeleitet wurden. Dadurch können die
                            Wirksamkeit der Facebook-Werbeanzeigen für
                            statistische und Marktforschungszwecke ausgewertet
                            werden und zukünftige Werbemaßnahmen optimiert
                            werden.
                          </p>{" "}
                          <p>
                            Die erhobenen Daten sind für uns als Betreiber
                            dieser Website anonym, wir können keine Rückschlüsse
                            auf die Identität der Nutzer ziehen. Die Daten
                            werden aber von Facebook gespeichert und
                            verarbeitet, sodass eine Verbindung zum jeweiligen
                            Nutzerprofil möglich ist und Facebook die Daten für
                            eigene Werbezwecke, entsprechend der{" "}
                            <a
                              href="https://de-de.facebook.com/about/privacy/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Facebook-Datenverwendungsrichtlinie
                            </a>{" "}
                            verwenden kann. Dadurch kann Facebook das Schalten
                            von Werbeanzeigen auf Seiten von Facebook sowie
                            außerhalb von Facebook ermöglichen. Diese Verwendung
                            der Daten kann von uns als Seitenbetreiber nicht
                            beeinflusst werden.
                          </p>{" "}
                          <p>
                            Die Nutzung von Facebook-Pixel erfolgt auf Grundlage
                            von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
                            hat ein berechtigtes Interesse an effektiven
                            Werbemaßnahmen unter Einschluss der sozialen Medien.
                            Sofern eine entsprechende Einwilligung abgefragt
                            wurde (z. B. eine Einwilligung zur Speicherung von
                            Cookies), erfolgt die Verarbeitung ausschließlich
                            auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                            Einwilligung ist jederzeit widerrufbar.
                          </p>{" "}
                          <p>
                            In den Datenschutzhinweisen von Facebook finden Sie
                            weitere Hinweise zum Schutz Ihrer Privatsphäre:{" "}
                            <a
                              href="https://de-de.facebook.com/about/privacy/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://de-de.facebook.com/about/privacy/
                            </a>
                            .
                          </p>{" "}
                          <p>
                            Sie können außerdem die Remarketing-Funktion „Custom
                            Audiences“ im Bereich Einstellungen für
                            Werbeanzeigen unter{" "}
                            <a
                              href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
                            </a>{" "}
                            deaktivieren. Dazu müssen Sie bei Facebook
                            angemeldet sein.
                          </p>{" "}
                          <p>
                            Wenn Sie kein Facebook Konto besitzen, können Sie
                            nutzungsbasierte Werbung von Facebook auf der
                            Website der European Interactive Digital Advertising
                            Alliance deaktivieren:{" "}
                            <a
                              href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              http://www.youronlinechoices.com/de/praferenzmanagement/
                            </a>
                            .
                          </p>
                          <h2>6. Newsletter</h2>
                          <h3>Newsletter­daten</h3>{" "}
                          <p>
                            Wenn Sie den auf der Website angebotenen Newsletter
                            beziehen möchten, benötigen wir von Ihnen eine
                            E-Mail-Adresse sowie Informationen, welche uns die
                            Überprüfung gestatten, dass Sie der Inhaber der
                            angegebenen E-Mail-Adresse sind und mit dem Empfang
                            des Newsletters einverstanden sind. Weitere Daten
                            werden nicht bzw. nur auf freiwilliger Basis
                            erhoben. Diese Daten verwenden wir ausschließlich
                            für den Versand der angeforderten Informationen und
                            geben diese nicht an Dritte weiter.
                          </p>{" "}
                          <p>
                            Die Verarbeitung der in das
                            Newsletteranmeldeformular eingegebenen Daten erfolgt
                            ausschließlich auf Grundlage Ihrer Einwilligung
                            (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte
                            Einwilligung zur Speicherung der Daten, der
                            E-Mail-Adresse sowie deren Nutzung zum Versand des
                            Newsletters können Sie jederzeit widerrufen, etwa
                            über den „Austragen“-Link im Newsletter. Die
                            Rechtmäßigkeit der bereits erfolgten
                            Datenverarbeitungsvorgänge bleibt vom Widerruf
                            unberührt.
                          </p>{" "}
                          <p>
                            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei
                            uns hinterlegten Daten werden von uns bis zu Ihrer
                            Austragung aus dem Newsletter bei uns bzw. dem
                            Newsletterdiensteanbieter gespeichert und nach der
                            Abbestellung des Newsletters aus der
                            Newsletterverteilerliste gelöscht. Daten, die zu
                            anderen Zwecken bei uns gespeichert wurden bleiben
                            hiervon unberührt.
                          </p>{" "}
                          <p>
                            Nach Ihrer Austragung aus der
                            Newsletterverteilerliste wird Ihre E-Mail-Adresse
                            bei uns bzw. dem Newsletterdiensteanbieter ggf. in
                            einer Blacklist gespeichert, um künftige Mailings zu
                            verhindern. Die Daten aus der Blacklist werden nur
                            für diesen Zweck verwendet und nicht mit anderen
                            Daten zusammengeführt. Dies dient sowohl Ihrem
                            Interesse als auch unserem Interesse an der
                            Einhaltung der gesetzlichen Vorgaben beim Versand
                            von Newslettern (berechtigtes Interesse im Sinne des
                            Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der
                            Blacklist ist zeitlich nicht befristet.{" "}
                            <strong>
                              Sie können der Speicherung widersprechen, sofern
                              Ihre Interessen unser berechtigtes Interesse
                              überwiegen.
                            </strong>
                          </p>
                          <h3>Sendinblue</h3>{" "}
                          <p>
                            Diese Website nutzt Sendinblue für den Versand von
                            Newslettern. Anbieter ist die Sendinblue GmbH,
                            Köpenicker Straße 126, 10179 Berlin, Deutschland.
                          </p>{" "}
                          <p>
                            Sendinblue ist ein Dienst, mit dem u.a. der Versand
                            von Newslettern organisiert und analysiert werden
                            kann. Die von Ihnen zum Zwecke des Newsletterbezugs
                            eingegeben Daten werden auf den Servern von
                            Sendinblue in Deutschland gespeichert.
                          </p>{" "}
                          <p>
                            Wenn Sie keine Analyse durch Sendinblue wollen,
                            müssen Sie den Newsletter abbestellen. Hierfür
                            stellen wir in jeder Newsletternachricht einen
                            entsprechenden Link zur Verfügung. Des Weiteren
                            können Sie den Newsletter auch direkt auf der
                            Website abbestellen.
                          </p>{" "}
                          <h4>Datenanalyse durch Sendinblue</h4>{" "}
                          <p>
                            Mit Hilfe von Sendinblue ist es uns möglich, unsere
                            Newsletter-Kampagnen zu analysieren. So können wir
                            z.&nbsp;B. sehen, ob eine Newsletter-Nachricht
                            geöffnet und welche Links ggf. angeklickt wurden.
                            Auf diese Weise können wir u.a. feststellen, welche
                            Links besonders oft angeklickt wurden.
                          </p>{" "}
                          <p>
                            Außerdem können wir erkennen, ob nach dem Öffnen/
                            Anklicken bestimmte vorher definierte Aktionen
                            durchgeführt wurden (Conversion-Rate). Wir können so
                            z.&nbsp;B. erkennen, ob Sie nach dem Anklicken des
                            Newsletters einen Kauf getätigt haben.
                          </p>{" "}
                          <p>
                            Sendinblue ermöglicht es uns auch, die
                            Newsletter-Empfänger anhand verschiedener Kategorien
                            zu unterteilen („clustern“). Dabei lassen sich die
                            Newsletterempfänger z.&nbsp;B. nach Alter,
                            Geschlecht oder Wohnort unterteilen. Auf diese Weise
                            lassen sich die Newsletter besser an die jeweiligen
                            Zielgruppen anpassen.
                          </p>{" "}
                          <p>
                            Ausführliche Informationen zum zu den Funktionen von
                            Sendinblue entnehmen Sie folgendem Link:{" "}
                            <a
                              href="https://de.sendinblue.com/newsletter-software/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://de.sendinblue.com/newsletter-software/
                            </a>
                            .
                          </p>{" "}
                          <h4>Rechtsgrundlage</h4>{" "}
                          <p>
                            Die Datenverarbeitung erfolgt auf Grundlage Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
                            können diese Einwilligung jederzeit widerrufen. Die
                            Rechtmäßigkeit der bereits erfolgten
                            Datenverarbeitungsvorgänge bleibt vom Widerruf
                            unberührt.
                          </p>{" "}
                          <h4>Speicherdauer</h4>{" "}
                          <p>
                            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei
                            uns hinterlegten Daten werden von uns bis zu Ihrer
                            Austragung aus dem Newsletter bei uns bzw. dem
                            Newsletterdiensteanbieter gespeichert und nach der
                            Abbestellung des Newsletters aus der
                            Newsletterverteilerliste gelöscht. Daten, die zu
                            anderen Zwecken bei uns gespeichert wurden bleiben
                            hiervon unberührt.
                          </p>{" "}
                          <p>
                            Nach Ihrer Austragung aus der
                            Newsletterverteilerliste wird Ihre E-Mail-Adresse
                            bei uns bzw. dem Newsletterdiensteanbieter ggf. in
                            einer Blacklist gespeichert, um künftige Mailings zu
                            verhindern. Die Daten aus der Blacklist werden nur
                            für diesen Zweck verwendet und nicht mit anderen
                            Daten zusammengeführt. Dies dient sowohl Ihrem
                            Interesse als auch unserem Interesse an der
                            Einhaltung der gesetzlichen Vorgaben beim Versand
                            von Newslettern (berechtigtes Interesse im Sinne des
                            Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der
                            Blacklist ist zeitlich nicht befristet.{" "}
                            <strong>
                              Sie können der Speicherung widersprechen, sofern
                              Ihre Interessen unser berechtigtes Interesse
                              überwiegen.
                            </strong>
                          </p>{" "}
                          <p>
                            Näheres entnehmen Sie den Datenschutzbestimmungen
                            von Sendinblue unter:{" "}
                            <a
                              href="https://de.sendinblue.com/datenschutz-uebersicht/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://de.sendinblue.com/datenschutz-uebersicht/
                            </a>
                            .
                          </p>
                          <h4>
                            Abschluss eines Vertrags über Auftragsverarbeitung
                          </h4>{" "}
                          <p>
                            Wir haben mit Sendinblue einen Vertrag
                            abgeschlossen, in dem wir Sendinblue verpflichten,
                            die Daten unserer Kunden zu schützen und sie nicht
                            an Dritte weiterzugeben.
                          </p>
                          <h2>7. Plugins und Tools</h2>
                          <h3>Google reCAPTCHA</h3>{" "}
                          <p>
                            Wir nutzen „Google reCAPTCHA“ (im Folgenden
                            „reCAPTCHA“) auf dieser Website. Anbieter ist die
                            Google Ireland Limited („Google“), Gordon House,
                            Barrow Street, Dublin 4, Irland.
                          </p>{" "}
                          <p>
                            Mit reCAPTCHA soll überprüft werden, ob die
                            Dateneingabe auf dieser Website (z.&nbsp;B. in einem
                            Kontaktformular) durch einen Menschen oder durch ein
                            automatisiertes Programm erfolgt. Hierzu analysiert
                            reCAPTCHA das Verhalten des Websitebesuchers anhand
                            verschiedener Merkmale. Diese Analyse beginnt
                            automatisch, sobald der Websitebesucher die Website
                            betritt. Zur Analyse wertet reCAPTCHA verschiedene
                            Informationen aus (z.&nbsp;B. IP-Adresse,
                            Verweildauer des Websitebesuchers auf der Website
                            oder vom Nutzer getätigte Mausbewegungen). Die bei
                            der Analyse erfassten Daten werden an Google
                            weitergeleitet.
                          </p>{" "}
                          <p>
                            Die reCAPTCHA-Analysen laufen vollständig im
                            Hintergrund. Websitebesucher werden nicht darauf
                            hingewiesen, dass eine Analyse stattfindet.
                          </p>{" "}
                          <p>
                            Die Speicherung und Analyse der Daten erfolgt auf
                            Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                            Websitebetreiber hat ein berechtigtes Interesse
                            daran, seine Webangebote vor missbräuchlicher
                            automatisierter Ausspähung und vor SPAM zu schützen.
                            Sofern eine entsprechende Einwilligung abgefragt
                            wurde, erfolgt die Verarbeitung ausschließlich auf
                            Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                            Einwilligung ist jederzeit widerrufbar.
                          </p>{" "}
                          <p>
                            Weitere Informationen zu Google reCAPTCHA entnehmen
                            Sie den Google-Datenschutzbestimmungen und den
                            Google Nutzungsbedingungen unter folgenden Links:{" "}
                            <a
                              href="https://policies.google.com/privacy?hl=de"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://policies.google.com/privacy?hl=de
                            </a>{" "}
                            und{" "}
                            <a
                              href="https://policies.google.com/terms?hl=de"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="break-all"
                            >
                              https://policies.google.com/terms?hl=de
                            </a>
                            .
                          </p>
                          <h2>8. eCommerce und Zahlungs­anbieter</h2>
                          <h3>
                            Verarbeiten von Daten (Kunden- und Vertragsdaten)
                          </h3>{" "}
                          <p>
                            Wir erheben, verarbeiten und nutzen personenbezogene
                            Daten nur, soweit sie für die Begründung,
                            inhaltliche Ausgestaltung oder Änderung des
                            Rechtsverhältnisses erforderlich sind
                            (Bestandsdaten). Dies erfolgt auf Grundlage von Art.
                            6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
                            Daten zur Erfüllung eines Vertrags oder
                            vorvertraglicher Maßnahmen gestattet.
                            Personenbezogene Daten über die Inanspruchnahme
                            dieser Website (Nutzungsdaten) erheben, verarbeiten
                            und nutzen wir nur, soweit dies erforderlich ist, um
                            dem Nutzer die Inanspruchnahme des Dienstes zu
                            ermöglichen oder abzurechnen.
                          </p>{" "}
                          <p>
                            Die erhobenen Kundendaten werden nach Abschluss des
                            Auftrags oder Beendigung der Geschäftsbeziehung
                            gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
                            unberührt.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
